import React, { useEffect, useState } from 'react'
import Axios from 'axios'
import { BsCheckCircleFill, BsXCircleFill  } from 'react-icons/bs'
import MBSPLogo from './assets/MBSP_logo.png'
import ICollectLogo from './assets/icollect.png'
import LatarBelakang from './assets/latarBelakang.jpg'
import PayPerUse from './assets/payPerUse.jpg'
import ViewPhone from './assets/viewphone.png'
import Step from './assets/step.png'
import Photo1 from './assets/photo1.png'
import Photo2 from './assets/photo2.png'
import PDFIcon from './assets/pdf.png'
import ReactPlayer from "react-player"

import './App.css';

const App = () => {
  const [schedules, setSchedules] = useState( [] )

  useEffect(() => {
    getScheduels()
  }, [])

  const getScheduels = async () => {
    try {
      const res = await Axios.get( 'https://asia-southeast1-i-collect-sws.cloudfunctions.net/getSchedules' )
      setSchedules( res.data )      
    } catch ( error ) {
      console.log( error )
    }
  }

  return (
    <div className="App">
      <div className='headerDiv'>
        <img style={{ width: '50px', margin: '5px' }} src={ MBSPLogo } alt='MBSP Logo' />
        <img style={{ width: '150px', margin: '5px 5px 5px 20px' }} src={ ICollectLogo } alt='I-Collect Logo' />
        <p className='hidden' style={{ color: 'white' }}>I-Collect</p>
      </div>
      <div className='contentDiv'>
        <div className='banner'>
        </div>
        <div className='responsive-display' style={{ width: '100%', background: 'white', padding: '10px 0px' }}>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'system' ).scrollIntoView({ behavior: 'smooth', block: 'start' }) }>
            System I-Collect
          </button>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'latarBelankang' ).scrollIntoView({ behavior: 'smooth', block: 'center' }) }>
            Latar Belakang
          </button>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'apaItu' ).scrollIntoView({ behavior: 'smooth', block: 'center' }) }>
            Apa Itu I-Collect
          </button>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'caj' ).scrollIntoView({ behavior: 'smooth', block: 'center' }) }>
            Caj Perkhidmatan
          </button>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'kadar' ).scrollIntoView({ behavior: 'smooth', block: 'center' }) }>
            Kadar Bayaran
          </button>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'jadual' ).scrollIntoView({ behavior: 'smooth', block: 'center' }) }>
            Jadual Kutipan
          </button>
          <button className='btn-navigate' onClick={ () => document.getElementById( 'video' ).scrollIntoView({ behavior: 'smooth', block: 'center' }) }>
            Video
          </button>
        </div>
        <div id="system" style={{ background: '#08253a', padding: 10 }} >
          <div className={ 'responsive-display' } style={{ maxWidth: 1000, margin: 'auto', padding: '50px 0px' }}>
            <div style={{ padding: 10 }}>
              <img src={ ICollectLogo } alt={ 'Icollect' } style={{ width: '30%', borderRadius: 5, background: 'white', padding: 10 }}/>
              <p style={{ fontSize: 18, padding: '0px 20px', color: 'white', textAlign: 'left', marginBottom: 50 }}>
                Sistem i-Collect MBSP diwujudkan bagi memudahkan urusan pelupusan sisa pepejal premis-premis perniagaan di Seberang Perai. MBSP memberikan perkhidmatan kutipan sisa pepejal secara kutipan door-to-door dengan kadar bayaran yang sesuai. Sistem i-Collect MBSP ini merupakan satu kaedah mesra pengguna dan seiring dengan keperluan norma baharu pandemik iaitu menggunakan konsep “pay as you use”. 
                <br/>
                Hal demikian bermaksud pengguna hanya akan dikenakan caj perkhidmatan ketika premis perniagaan beroperasi dan menjana sisa pepejal. Pengguna dapat memuat turun aplikasi pintar i-Collect MBSP untuk urusan pendaftaran, tambah nilai dan akses status kutipan sampah dan baki akaun secara masa nyata.
              </p>
            </div>
            <img src={ ViewPhone } alt={ 'View Phone' } style={{ width: '30%', borderRadius: 5, objectFit: 'contain' }}/>
          </div>
          <div className={ 'responsive-display' } style={{ maxWidth: 1000, margin: 'auto', marginBottom: 50 }}>
            <img src={ Step } alt={ 'Step Image' } style={{ width: '60%' , borderRadius: 5, margin: 5, marginLeft: 0  }}/>
            <div>
              <img src={ Photo1 } alt={ 'Photo 1' } style={{ width: '90%', borderRadius: 5, margin: 5, marginRight: 0 }}/>
              <img src={ Photo2 } alt={ 'Photo 2' } style={{ width: '90%', borderRadius: 5, margin: 5, marginRight: 0 }}/>
            </div>
          </div>
        </div>
        <div className='pattern' style={{ padding: 10 }}>
          <h3 id="latarBelankang" style={{ fontSize: 30, marginBottom: 5 }}>Latar Belakang</h3>
          <hr style={{ width: 200, borderTop: '5px solid white', borderRadius: 25, color: 'white', height: 0, marginBottom: 20 }}/>
          <div className={ 'responsive-display' } style={{ maxWidth: 1000, margin: 'auto', paddingBottom: 50 }}>
            <p style={{ fontSize: 18, padding: '0px 20px', textAlign: 'right' }}>
              Premis-premis perniagaan pada dasarnya perlu melupuskan sendiri sisa pepejal ke Tapak Pelupusan Pulau Burung, namun terdapat segelintir pemilik premis perniagaan membuang sisa di tong komunal di tepi jalan milik MBSP untuk kegunaan penduduk kampung. 
              <br/>
              Oleh itu, Jabatan mengambil inisiatif bagi menangani isu ini dengan mewujudkan satu sistem yang dapat membantu pemilik perniagaan dan juga meningkatkan efisiensi Majlis dari segi perkhidmatan pelupusan sisa pepejal.
            </p>
            <img src={ LatarBelakang } alt={ 'Latar Belakang' } style={{ width: '30%', borderRadius: 5 }}/>
          </div>
          <h3 id="apaItu" style={{ fontSize: 30, marginBottom: 5 }}>Apa Itu Sistem I-Collect</h3>
          <hr style={{ width: 310, borderTop: '5px solid white', borderRadius: 25, color: 'white', height: 0, marginBottom: 20 }}/>
          <ul style={{ maxWidth: 1000, margin: 'auto' }}>
            <li>Merupakan satu sistem pengurusan dan perkhidmatan kutipan sisa pepejal bagi premis-premis perniagaan dan sebahagian IKS di Seberang Perai.</li>
            <li>Sistem i – Collect MBSP merupakan satu kaedah mesra pengguna dan seiring dengan keperluan norma baharu menggunakan konsep Pay-As-You-Use.</li>
            <li>Pengguna hanya dikenakan caj servis ketika premis perniagaan beroperasi dan menjana sisa pepejal</li>
            <li>Premis perniagaan yang berdaftar hanya perlu menyediakan tong sampah mengikut spesifikasi yang ditetapkan oleh Majlis, bersaiz  minimum  240 liter, bertudung dan mencukupi mengikut keperluan perniagaan yang dijalankan.</li>
          </ul>
          <h3 id="caj" style={{ fontSize: 30, marginBottom: 5, marginTop: 100 }}>Caj Perkhidmatan</h3>
          <hr style={{ width: 250, borderTop: '5px solid white', borderRadius: 25, color: 'white', height: 0, marginBottom: 20 }}/>
          <div className={ 'responsive-display' } style={{ maxWidth: 1000, margin: 'auto', marginBottom: 100 }}>
            <img src={ PayPerUse } alt={ 'Pay Per Use' } style={{ width: '30%', borderRadius: 5, zIndex: 99 }}/>
            <p style={{ fontSize: 18, padding: '0px 20px', textAlign: 'left', zIndex: 99, marginBottom: 100 }}>
              Sistem i-Collect MBSP ini merupakan satu kaedah mesra pengguna dan seiring dengan keperluan norma baharu menggunakan konsep Pay-Per-Use. Hal demikian bermaksud pengguna hanya akan dikenakan caj servis ketika premis perniagaan beroperasi dan menjana sisa pepejal.
            </p>
          </div>
          <h3 id="kadar" style={{ fontSize: 30, marginBottom: 5, color: 'black', position: 'relative', zIndex: 99 }}>Kadar Bayaran</h3>
          <hr style={{ width: 310, borderTop: '5px solid black', borderRadius: 25, height: 0, marginBottom: 20, position: 'relative', zIndex: 99 }}/>
        </div>
        <div style={{ background: 'white', padding: 10 }} >
          <div style={{ maxWidth: 1000, margin: 'auto' }}>
            <p style={{ fontSize: 18, padding: '0px 20px', textAlign: 'left', zIndex: 99, color: 'black', marginBottom: 50 }}>
              Perkhidmatan Sistem i-Collect MBSP ini akan dikenakan kadar bayaran berdasarkan jenis dan bilangan tong yang disediakan oleh premis perniagaan. MBSP akan menjalankan servis kutipan sisa pepejal secara door-to-door collection dengan kadar bayaran seperti berikut:
            </p>
            <table id='customers' style={{ margin: 'auto', marginBottom: 50 }}>
              <tr>
                <th>BIL</th>
                <th>JENIS TONG MGB (LITER)</th>
                <th>KADAR BAYARAN (RM)</th>
                <th>JUMLAH TONG</th>
                <th>KEKERAPAN kUTIPAN</th>
              </tr>
              <tbody>
                <tr>
                  <th>1</th>
                  <td>240</td>
                  <td>1.00</td>
                  <td>1</td>
                  <td>Sehari Sekali</td>
                </tr>
                <tr>
                  <th>2</th>
                  <td>660</td>
                  <td>3.00</td>
                  <td>1</td>
                  <td>Sehari Sekali</td>
                </tr>
              </tbody>
            </table>
            <p style={{ fontSize: 18, padding: '0px 20px', textAlign: 'left', zIndex: 99, color: 'black', marginBottom: 50 }}>
              Kutipan sisa pepejal akan dijalankan oleh MBSP secara harian di premis perniagaan berkenaan. Jadual di atas adalah <b>kadar bayaran minimum</b> yang akan dikenakan kepada premis perniagaan. Tiada sebarang caj perkhidmatan kutipan akan dikenakan kepada premis perniagaan yang tidak beroperasi dan tidak menjana sisa. Buat masa sekarang, Majlis memberikan perkhidmatan ini secara <b>percuma</b> sehingga satu waktu yang akan di maklumkan kelak.
            </p>
          </div>
          <h3 id="jadual" style={{ fontSize: 30, marginBottom: 5, color: 'black', position: 'relative', zIndex: 99 }}>Jadual Kutipan</h3>
          <hr style={{ width: 310, borderTop: '5px solid black', borderRadius: 25, height: 0, marginBottom: 20, position: 'relative', zIndex: 99 }}/>
          <table id='customers' style={{ margin: 'auto', marginBottom: 50 }}>
              <tr>
                <th>Jadual</th>
                <th>Ahad</th>
                <th>Isnin</th>
                <th>Selasa</th>
                <th>Rabu</th>
                <th>Khamis</th>
                <th>Jumaat</th>
                <th>Sabtu</th>
              </tr>
              <tbody>
                { schedules.map( ( schedule, index ) => (
                  <tr key={ index }>
                    <th>{ schedule.name }</th>
                    <td>{ schedule.sun ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                    <td>{ schedule.mon ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                    <td>{ schedule.tue ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                    <td>{ schedule.wed ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                    <td>{ schedule.thu ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                    <td>{ schedule.fri ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                    <td>{ schedule.sat ? <BsCheckCircleFill style={{color: 'green'}}/> : <BsXCircleFill style={{color: 'red'}} /> }</td>
                  </tr>
                )) }
              </tbody>
            </table>
        </div>
        <div id='video'>
          <h3>i-Collect MBSP Promo</h3>
          <div className='videoDiv'>
            <ReactPlayer
              style={{ margin: 'auto' }}
              controls={ true }
              url="https://www.youtube.com/watch?v=9ucYtn15w8A"
            />
          </div>
        </div>
        <div>
          <h3>i-Collect MBSP Tutorial</h3>
          <div className='videoDiv'>
            <ReactPlayer
              style={{ margin: 'auto' }}
              controls={ true }
              url="https://www.youtube.com/watch?v=z10ZvYCRnJ8"
            />
          </div>
        </div>
        <div>
          <h3>Other Information</h3>
          <div className='other-info-cont'>
            <div className='info-cont' onClick={ () => window.open( 'https://storage.googleapis.com/i-collect-sws.appspot.com/I-Collect%20User%20Manual%20-%20Public.pdf', '_blank' )}>
              <img style={{ width: '50px', margin: '5px' }} src={ PDFIcon } alt='PDF Logo' />
              I-Collect Manual
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className='footer-content'>
          <div className='content-card'>
            <p><b>Alamat:</b></p>
            Majlis Bandaraya Seberang 
            Perai, Menara Bandaraya, Jln 
            Perda Utama, Bandar Baru 
            Perda, 14000 Bukit Mertajam, 
            Pulau Pinang
          </div>
          <div className='content-card'>
            <p><b>No. Telefon:</b></p>
            04-5497661 (En. Iskandar)
            <br/>
            04-5497555
          </div>
          <div className='content-card'>
            <p><b>Emel:</b></p>
            <a href={ 'mailto:strategikJPP@mbsp.gov.my' }>strategikJPP@mbsp.gov.my</a>
          </div>
        </div>
        <p style={{ color: 'white', fontSize: '18px', fontWeight: '500' }}>MBSP i-Collect | Powered by Visionlytics</p>
        <img style={{ width: '100px', margin: '5px 30px' }} src={ MBSPLogo } alt='MBSP Logo' />
        <img style={{ width: '285px', padding: '10px', backgroundColor: 'white', borderRadius: '25px' }} src={ ICollectLogo } alt='I-Collect Logo' />
      </footer>
    </div>
  );
}

export default App;
